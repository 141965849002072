import { Flex } from 'antd';
import parse from 'html-react-parser';
import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Server } from "../../server/server";
import { CaseOrTalent, GoodsCategory, InterviewTimes, MatchedTalentStatus, UnlockType, getInterviewTimes, getInterviewWay, getMatchedLevel, getMathcedTalentStatus } from '../common/common';
import Chat from '../elements/message/Chat';
import CaseEventList from "../elements/talent/CaseEventList";
import InterviewList from "../elements/talent/InterviewList";
import AlertModal from "../modals/AlertModal";
import InterviewModal from '../modals/InterviewModal';
import MessageModal from "../modals/MessageModal";
import withNavigation from "../util/withNavigation";
import './MatchedTalentPage.css';
import ConfirmDialogModal from '../modals/ConfirmDialogModal';

/**
 * 已匹配的他社要员详情页面
 */
class MatchedTalentPage extends React.Component<MatchedTalentPageProps, MatchedTalentPageState> {

    // 匹配id
    private id: string;
    private childRefOfIntervew:any;
    private childRefOfCaseEvent:any;
    // 交流组件引用
    private chatRef:any;
    // 面试对话框引用
    private interviewRef:any;
    // 确认对话框引用
    private confirmDialogRef:any;

    constructor(props: any) {
        super(props)

        // 从url中获取匹配id
        const { search } = window.location
        const searchParams = new URLSearchParams(search)
        let mid = searchParams.get('id')
        if (mid == undefined) mid = ''
        this.id = mid.toString()
        // console.log("id: %s", this.id)

        // const params = props.params
        // console.log("params: %o" , params)
        // 匹配id
        // this.id = params.id

        // 初始化状态
        this.state = {
            userId: '',
            caseId: '',
            caseBrief: '',
            caseUserId: '',
            matchedType: -1,
            matchedPercent: '',
            talentId: '',
            talentUserId: '',
            talentText: '',
            talentStatus: -1,
            talentResume: '',
            interviewList: [],
            eventList: [],
            isPremiumUser: false,
            isUnlocked: false,
            balanceAmount: 0,

            openInterview: false,
            message: '',
            alert: '',
            loading: false,
        }

        this.childRefOfIntervew = React.createRef();
        this.childRefOfCaseEvent = React.createRef();
        this.chatRef = React.createRef();
        this.interviewRef = React.createRef();
        this.confirmDialogRef = React.createRef();
    }

    componentDidMount(): void {
        this.getMatchedTalentInfo(true)
    }

    render() {
        const { t } = this.props
        if(this.state.loading) {
            return (<div>{t('pages.MatchedTalentPage.957078-22')}</div>)
        }
        const userId = this.state.userId
        const caseId = this.state.caseId
        const caseBrief = this.state.caseBrief
        const caseUserId = this.state.caseUserId
        const talentId = this.state.talentId
        const talentUserId = this.state.talentUserId
        const talentResume = this.state.talentResume
        const talentMatchedPercent = this.state.matchedPercent
        const matchedLevel = getMatchedLevel(talentMatchedPercent)
        const talentStatus = this.state.talentStatus
        const matchedType = this.state.matchedType
        const talentStatusStr = getMathcedTalentStatus(matchedType, talentStatus, false, t)
        const talentText = this.state.talentText
        const interviewList = this.state.interviewList
        // console.log("interviewList:" , interviewList)
        const eventList = this.state.eventList
        // console.log("eventList:" , eventList)
        const openInterview = this.state.openInterview
        const isUnlocked = this.state.isUnlocked
        const isPremiumUser = this.state.isPremiumUser
        const balanceAmount = this.state.balanceAmount
        // 是否当前登录者的案件（防止从广场主体页面进入）已无用处，主题页面的匹配列表都为登录者自己的案件或要员
        const isMyCase = (userId == caseUserId)
        // console.log('isMyCase:', isMyCase)
        return(
            <div className="main">
                <div className="case-info">
                    {caseBrief}
                </div>
                
                <div className="matched-talent-info">
                    <div className="title">
                        <div className="left">
                            {talentStatusStr && <span className="status">{talentStatusStr}</span>}
                        </div>
                        {userId == talentUserId && <div className='middle'>{t('pages.MatchedTalentPage.957078-0')}</div>}
                        <div className="right">
                            <div className="matched-talent-status">
                                {talentMatchedPercent && <span className="match-percent" >{matchedLevel}</span>}
                            </div>
                        </div>
                    </div>
                    
                    <div className="content">{parse(talentText)}</div>
                    {(isUnlocked || isPremiumUser || (userId == talentUserId)) ? 
                        <div className="toolbar">
                            {isMyCase && this.renderButtons(talentStatus, talentResume)}
                        </div> :
                        <>
                            {balanceAmount >= 15 ? 
                            <div className='warning'>
                                <span>{t('pages.MatchedTalentPage.957078-1', {amount: 15})}<a onClick={this.onPurchaseClick}>{t('pages.MatchedTalentPage.957078-2')}</a>{t('pages.MatchedTalentPage.957078-3')}<a onClick={this.onPurchasePremiumUserClick}>{t('pages.MatchedTalentPage.957078-4')}</a>{t('pages.MatchedTalentPage.957078-5')}</span>
                            </div> :
                            <div className="warning">
                                <Flex vertical>
                                    <span>{t('pages.MatchedTalentPage.957078-1', {amount: 15})}<a style={{pointerEvents:"none", cursor: "default", color:"gray"}}>{t('pages.MatchedTalentPage.957078-2')}</a>{t('pages.MatchedTalentPage.957078-3')}<a onClick={this.onPurchasePremiumUserClick}>{t('pages.MatchedTalentPage.957078-4')}</a>{t('pages.MatchedTalentPage.957078-5')}</span>
                                    <span>{t('pages.MatchedTalentPage.957078-6')}{t('pages.MatchedTalentPage.957078-7', {amount: balanceAmount})}<a href={`/balance?redirect=${encodeURIComponent(window.location.href)}`}>{t('pages.MatchedTalentPage.957078-8')}</a>{t('pages.MatchedTalentPage.957078-9')}</span>
                                </Flex>
                            </div>
                            }
                        </>
                    }
                </div>

                {/* 交流信息 */}
                {(isUnlocked || isPremiumUser) && (caseId && talentId && (isMyCase && (caseUserId != talentUserId))) && <Chat type={CaseOrTalent.CASE} loginUserId={userId} caseId={caseId} talentId={talentId} placeholder={t('pages.MatchedTalentPage.957078-10')} ref={this.chatRef} ></Chat>}

                {/* 面试安排 */}
                {(isUnlocked || isPremiumUser) && <InterviewList title={t('pages.MatchedTalentPage.957078-11')} list={interviewList} refCaseId={caseId} showCase={false} ref={this.childRefOfIntervew} /> }

                {/* 案件营业情况 */}
                <CaseEventList list={eventList} caseId={caseId} ref={this.childRefOfCaseEvent} navigate={this.props.navigate}/>
                
                {/* 面试对话框 */}
                <InterviewModal 
                    ref={this.interviewRef}
                    open={openInterview} 
                    mid={this.id} 
                    onSubmitCallback={this.interviewSubmit}
                    onClose={() => this.setState({ openInterview: false })} />                
                
                <ConfirmDialogModal ref={this.confirmDialogRef} />
                <MessageModal message={this.state.message} />
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    renderButtons(matchedTalentStatus: number, talentResume: string) {
        const { t } = this.props
        if (matchedTalentStatus == MatchedTalentStatus.OPEN) {
            return (
                <>
                <div className="left">
                    { talentResume && <button onClick={() => {this.onResumeDownloadClick()}}>{t('pages.MatchedTalentPage.957078-12')}</button> }
                    <button onClick={() => {this.onInterviewClick()}}>{t('pages.MatchedTalentPage.957078-13')}</button>
                </div>
                <div className="right">
                    <button onClick={() => {this.onDiscardClick()}}>{t('pages.MatchedTalentPage.957078-14')}</button>
                    <button onClick={() => {this.onAlternativeClick()}}>{t('pages.MatchedTalentPage.957078-15')}</button>
                    <button onClick={() => {this.onEmployClick()}}>{t('pages.MatchedTalentPage.957078-16')}</button>
                </div>
                </>
            )
        } else if (matchedTalentStatus == MatchedTalentStatus.ALTERNATIVE) {
            return (
                <>
                <div className="left">
                    { talentResume && <button onClick={() => {this.onResumeDownloadClick()}}>{t('pages.MatchedTalentPage.957078-12')}</button> }
                    <button onClick={() => {this.onInterviewClick()}}>{t('pages.MatchedTalentPage.957078-13')}</button>
                </div>
                <div className="right">
                    <button onClick={() => {this.onDiscardClick()}}>{t('pages.MatchedTalentPage.957078-14')}</button>
                    {/* <button onClick={() => {this.onAlternativeClick()}}>{t('pages.MatchedTalentPage.957078-15')}</button> */}
                    <button onClick={() => {this.onEmployClick()}}>{t('pages.MatchedTalentPage.957078-16')}</button>
                </div>
                </>
            )
        } else if (matchedTalentStatus == MatchedTalentStatus.DISCARD) {
            return (
                <>
                <div className="left">
                    { talentResume && <button onClick={() => {this.onResumeDownloadClick()}}>{t('pages.MatchedTalentPage.957078-12')}</button> }
                    {/* <button onClick={() => {this.onInterviewClick()}}>{t('pages.MatchedTalentPage.957078-13')}</button> */}
                </div>
                <div className="right">
                    {/* <button onClick={() => {this.onDiscardClick()}}>{t('pages.MatchedTalentPage.957078-14')}</button> */}
                    <button onClick={() => {this.onAlternativeClick()}}>{t('pages.MatchedTalentPage.957078-15')}</button>
                    <button onClick={() => {this.onEmployClick()}}>{t('pages.MatchedTalentPage.957078-16')}</button>
                </div>
                </>
            )
        } else if (matchedTalentStatus == MatchedTalentStatus.EMPLOY_REQUEST) {
            return (
                <>
                <div className="left">
                    { talentResume && <button onClick={() => {this.onResumeDownloadClick()}}>{t('pages.MatchedTalentPage.957078-12')}</button> }
                    {/* <button onClick={() => {this.onInterviewClick()}}>{t('pages.MatchedTalentPage.957078-13')}</button> */}
                </div>
                <div className="right">
                    {/* <button onClick={() => {this.onDiscardClick()}}>{t('pages.MatchedTalentPage.957078-14')}</button> */}
                    {/* <button onClick={() => {this.onAlternativeClick()}}>{t('pages.MatchedTalentPage.957078-15')}</button> */}
                    {/* <button onClick={() => {this.onEmployClick()}}>{t('pages.MatchedTalentPage.957078-16')}</button> */}
                </div>
                </>
            )
        } else if (matchedTalentStatus == MatchedTalentStatus.EMPLOY_REQUEST_REFUSED) {
            return (
                <>
                <div className="left">
                    { talentResume && <button onClick={() => {this.onResumeDownloadClick()}}>{t('pages.MatchedTalentPage.957078-12')}</button> }
                    {/* <button onClick={() => {this.onInterviewClick()}}>{t('pages.MatchedTalentPage.957078-13')}</button> */}
                </div>
                <div className="right">
                    <button onClick={() => {this.onDiscardClick()}}>{t('pages.MatchedTalentPage.957078-14')}</button>
                    <button onClick={() => {this.onAlternativeClick()}}>{t('pages.MatchedTalentPage.957078-15')}</button>
                    <button onClick={() => {this.onEmployClick()}}>{t('pages.MatchedTalentPage.957078-16')}</button>
                </div>
                </>
            )
        } else if (matchedTalentStatus == MatchedTalentStatus.EMPLOY_REQUEST_OK) {
            return (
                <>
                <div className="left">
                    { talentResume && <button onClick={() => {this.onResumeDownloadClick()}}>{t('pages.MatchedTalentPage.957078-12')}</button> }
                    {/* <button onClick={() => {this.onInterviewClick()}}>{t('pages.MatchedTalentPage.957078-13')}</button> */}
                </div>
                <div className="right">
                    {/* <button onClick={() => {this.onDiscardClick()}}>{t('pages.MatchedTalentPage.957078-14')}</button> */}
                    {/* <button onClick={() => {this.onAlternativeClick()}}>{t('pages.MatchedTalentPage.957078-15')}</button> */}
                    {/* <button onClick={() => {this.onEmployClick()}}>{t('pages.MatchedTalentPage.957078-16')}</button> */}
                </div>
                </>
            )
        }
    }

    /**
     * 履历下载
     */
    onResumeDownloadClick() {
        const { t } = this.props
        const resumeUrl = this.state.talentResume
        Server.talent.downloadResume2(resumeUrl).catch(err => {
            this.setState({
                alert: t('pages.MatchedTalentPage.957078-17')
            })
        })
    }

    // 面试安排
    onInterviewClick() {
        this.setState({
            openInterview: true
        },() => {
            this.interviewRef.current.loadInterview(this.id, InterviewTimes.FIRST)
        })
    }

    /**
     * 面试提交后回调
     */
    interviewSubmit = (data:any) => {
        // 发送面试消息
        const { t } = this.props
        // console.log(data)
        const {times, timesTotal, dateTimeStr, way, place, memo, isUpdate} = data
        // console.log(place)
        let timesStr = getInterviewTimes(times, timesTotal)
        let wayStr = getInterviewWay(way)
        const noticeTitle4Update = t('pages.MatchedTalentPage.957078-18') // 面试变更通知
        const noticeTitle = t('pages.MatchedTalentPage.957078-19') // 面试通知
        const title = isUpdate ? noticeTitle4Update : noticeTitle
        const memoStr = memo != null ? '\n' + memo : ""
        const message = title + '\n' + timesStr + ' ' + dateTimeStr + ' ' + wayStr + '\n' + place + memoStr
        // console.log("chat ref:", this.chatRef.current!=null)
        const caseUserId = this.state.caseUserId
        const talentUserId = this.state.talentUserId
        if(caseUserId != talentUserId) {
            // 案件和要员管理者不为同一人时才执行消息发送
            this.chatRef.current.onMessageSend(message, true)
        }
        // 刷新数据
        this.getMatchedTalentInfo()
    }

    /**
     * 放弃
     */
    onDiscardClick() {
        const { t } = this.props
        Server.match.matchedTalentDiscard(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
    
            this.setState({
                talentStatus: MatchedTalentStatus.DISCARD,
                alert: t('pages.MatchedTalentPage.957078-20')
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedTalentPage.957078-21')
            })
        })
    }

    /**
     * 备选
     */
    onAlternativeClick() {
        const { t } = this.props
        Server.match.matchedTalentAlternative(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
    
            this.setState({
                talentStatus: MatchedTalentStatus.ALTERNATIVE,
                alert: t('pages.MatchedTalentPage.957078-20')
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedTalentPage.957078-21')
            })
        })
    }

    /**
     * 采用申请
     */
    onEmployClick() {
        const { t } = this.props
        // 弹出确认对话框
        this.confirmDialogRef.current.build({
            title: t('pages.MatchedTalentPage.957078-23'),
            message: t('pages.MatchedTalentPage.957078-24'),
            okText: t('pages.MatchedTalentPage.957078-25'),
            okCallback: ()=> {
                this.onEmployRequestCallback()
            },
            cancelText: t('pages.MatchedTalentPage.957078-26'),
            cancelCallback: () => {
                
            }
        })
    }

    onEmployRequestCallback = () => {
        const { t } = this.props
        Server.match.matchedTalentEmployRequest(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
    
            this.setState({
                talentStatus: MatchedTalentStatus.EMPLOY_REQUEST,
                alert: t('pages.MatchedTalentPage.957078-27')
            })

            // 刷新案件营业状况
            this.childRefOfCaseEvent.current.getCaseEvents(this.id)

        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedTalentPage.957078-28')
            })
        })
    }
    
    // 激活
    onPurchaseClick = () => {
        const mid = this.id
        const caseId = this.state.caseId
        const talentId = this.state.talentId
        const url = window.location.pathname + '?id=' + this.id
        window.location.href='/purchase?gtype='+GoodsCategory.TALENT + '&unlock=' + UnlockType.TALENT + '&m='+ mid + '&c='+caseId + '&t='+talentId + '&url='+ encodeURIComponent(url)
    }

    // 购买会员
    onPurchasePremiumUserClick = () => {
        const url = window.location.pathname + '?id=' + this.id
        window.location.href='/purchase?gtype='+GoodsCategory.PREMIUM + '&url=' + encodeURIComponent(url)
    }

    /**
     * 获取匹配的要员的信息
     */
    private async getMatchedTalentInfo(showLoading:boolean = false) {
        if(showLoading) {
            this.setState({loading: true})
        }
        // 获取匹配信息
        Server.match.getMatchedTalentInfo(this.id).then(res => {
            if (res.success) {
                // console.log(JSON.stringify(res))
                const {
                    userId,
                    caseId, caseUserId, talentId, talentUserId,
                    caseBrief, matchedType, matchedPercent, matchedTalentText, 
                    matchedTalentStatus,matchedTalentResume,
                    matchedTalentInterviews, matchedCaseEvents,
                    isUnlocked, isPremiumUser
                } = res.data

                this.setState({
                    userId: userId,
                    caseId: caseId,
                    caseBrief: caseBrief,
                    caseUserId: caseUserId,
                    matchedType: matchedType,
                    matchedPercent: matchedPercent,
                    talentId: talentId,
                    talentUserId: talentUserId,
                    talentText: matchedTalentText,
                    talentResume: matchedTalentResume,
                    talentStatus: matchedTalentStatus,
                    interviewList: matchedTalentInterviews,
                    eventList: matchedCaseEvents,
                    isPremiumUser: isPremiumUser,
                    isUnlocked: isUnlocked,
                }, ()=> {
                    if(showLoading) {
                        this.setState({loading: false})
                    }
                })

                if(!isUnlocked && !isPremiumUser) {
                    Server.account.balance().then(res => {
                        if (res.success && res.data) {
                            const {amount, version} = res.data
                            this.setState({
                                balanceAmount: Number(Number(amount).toFixed(0))
                            })
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                }
            }
        }).catch(err => {
            console.error(err)
            if(showLoading) {
                this.setState({loading: false})
            }
        })
    }

}

export default withTranslation()(withNavigation(MatchedTalentPage))

interface MatchedTalentPageProps extends WithTranslation {
    // 导航
    navigate: any,
}

interface MatchedTalentPageState {
    // 当前登录用户
    userId: string;
    // 案件id
    caseId: string;
    // 案件概要
    caseBrief:string;
    // 案件所属用户id
    caseUserId: string;
    // 匹配类型（自动、人工）
    matchedType: number;
    // 匹配百分比
    matchedPercent: string;
    // 匹配的要员id
    talentId: string;
    // 匹配的要员所属用户id
    talentUserId: string;
    // 匹配的要员原文
    talentText:string;
    // 匹配的要员状态
    talentStatus: number;
    // 匹配的要员履历书
    talentResume:string;
    // 该要员的所有面试安排
    interviewList: any;
    // 与要员匹配的案件的营业状况
    eventList: any;
    // 当前用户是否付费会员用户
    isPremiumUser: boolean;
    // 匹配的案件是否已激活
    isUnlocked: boolean;
    // 用户余额（梧桐果个数）
    balanceAmount: number;

    // 是否打开面试对话框
    openInterview: boolean;
    // 面试对话框标题（1第一次面试、2第二次面试）
    // interviewTime: number;
    message:string;
    alert: string;
    loading: boolean;
}