import Api from "../app/api/Api";
import { Service } from "./service";

export class CaseService extends Service {

    constructor(){
        super()
    }

    /**
     * 获取所有案件
     */
    public async getAllCase() {
        let response = await this.getCommand('case', 'all-cases-page');
        // console.log("getAllCase:", response)

        if(response.status != 200)
            return {success: false, message: response.body.message};
        return {success: true};
    }

    public async searchCases(params: any) {
        const {keyword, size, index} = params
        // 案件检索
        return await Api.post('/api/case/search',{keyword, size, index})
    }

    public async getTopicCases(topic: string) {
        // 获取主题案件
        return await Api.post('/api/case/topic-cases',{topic: topic})
    }

    public async getManualMatchedTalents(id: string) {
        // 获取某个案件手动匹配的要员列表
        return await Api.post('/api/case/'+ id + '/manual-matched-talents')
    }

    public async manualMatch(caseId:string, talentId:string) {
        // 手动将案件和要员进行匹配
        return await Api.post('/api/case/domatch', {caseId, talentId})
    }

    public async getMyCasesNotMathed(talentId: string) {
        // 查询当前用户管理的案件且未与指定要员匹配的案件
        return await Api.post('/api/case/my-cases-not-matched' , {talentId})
    }

    public async getCaseSampleInfo(id: string) {
        // 获取案件简要信息（概要、HTML版原文等）
        return await Api.get('/api/case/'+id+'/sample')
    }

    public async getCase(id: string) {
        // 获取案件详情(含匹配的要员)
        return await Api.get('/api/case/'+id)
    }

    public async getMyCases() {
        // 获取我的案件（所有）
        return await Api.get('/api/case/my-cases-all')
    }

    public async getCaseEvents(id: string) {
        // 获取案件的营业情况
        return await Api.get('/api/case/' + id + '/events')
    }

    public async postCase(caseHtml:string, caseText:string) {
        return await Api.post('/api/case/create', {text: caseText, html: caseHtml})
    }

    public async openCase(id:string) {
        return await Api.post('/api/case/'+id+"/open")
    }

    public async closeCase(id:string) {
        return await Api.post('/api/case/'+id+"/close")
    }

    public async updateCase(id:string, text:string, html:string) {
        return await Api.post('/api/case/'+id+'/update', {text: text, html: html})
    }

    public async doMatch(id:string) {
        return await Api.post('/api/case/'+id+'/domatch')
    }
}