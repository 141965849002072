import { Flex } from 'antd';
import parse from 'html-react-parser';
import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Server } from "../../server/server";
import { CaseOrTalent, GoodsCategory, MatchedCaseStatus, UnlockType, getMatchedLevel, getMathcedCaseStatus } from "../common/common";
import Chat from '../elements/message/Chat';
import InterviewList from '../elements/talent/InterviewList';
import AlertModal from '../modals/AlertModal';
import ConfirmDialogModal from '../modals/ConfirmDialogModal';
import MessageModal from '../modals/MessageModal';
import withNavigation from '../util/withNavigation';
import './MatchedCasePage.css';

class MatchedCasePage extends React.Component<MatchedCasePageProps, MatchedCasePageState> {
    // 匹配id
    private id: string;
    // 交流组件引用
    private chatRef:any;
    // 该要员面试安排列表
    private talentInterviewRef:any;
    // 确认对话框引用
    private confirmDialogRef:any;

    constructor(props: any) {
        super(props)

        // 从url中获取匹配id
        const { search } = window.location
        const searchParams = new URLSearchParams(search)
        let mid = searchParams.get('id')
        if (mid == undefined) mid = ''
        this.id = mid.toString()
        // console.log("id: %s", this.id)

        // const params = props.params
        // console.log("params: %o" , params)
        // 匹配id
        // this.id = params.id

        // 初始化状态
        this.state = {
            userId: '',
            talentId: '',
            talentBrief: '',
            talentUserId: '',
            matchedType: -1,
            matchedPercent: '',
            matchedCaseId: '',
            matchedCaseUserId: '',
            matchedCaseText: '',
            matchedCaseStatus: -1,
            matchedTalentInterviews: [],
            isPremiumUser: false,
            isUnlocked: false,
            balanceAmount: 0,

            // 提示消息
            message: '',
            // 错误消息
            alert: '',
            // 加载状态
            loading: false
        }

        this.chatRef = React.createRef();
        this.talentInterviewRef = React.createRef();
        this.confirmDialogRef = React.createRef();
    }

    componentDidMount(): void {
        this.getMatchedCaseInfo()
    }

    render(): React.ReactNode {
        const { t } = this.props
        if(this.state.loading) {
            return (<div>{t('pages.MatchedCasePage.325802-22')}</div>)
        }
        const userId = this.state.userId
        const talentId = this.state.talentId
        const talentBrief = this.state.talentBrief
        const talentUserId = this.state.talentUserId
        const matchedCaseId = this.state.matchedCaseId
        const matchedCaseUserId = this.state.matchedCaseUserId
        const matchedCaseStatus = this.state.matchedCaseStatus
        const matchedType = this.state.matchedType
        const matchedCaseStatusStr = getMathcedCaseStatus(matchedType, matchedCaseStatus, false, t)
        const matchedPercent = this.state.matchedPercent
        const matchedLevel = getMatchedLevel(matchedPercent)
        const matchedCaseText = this.state.matchedCaseText
        const matchedTalentInterviews = this.state.matchedTalentInterviews
        const isUnlocked = this.state.isUnlocked
        const isPremiumUser = this.state.isPremiumUser
        const balanceAmount = this.state.balanceAmount
        const isMyTalent = (userId == talentUserId)
        // console.log('isMyTalent:', isMyTalent)
        return (
            <div className="main">
                <div className="talent-info">
                    {talentBrief}
                </div>
                
                <div className="matched-case-info">
                    <div className="title">
                        <div className="left">
                            {matchedCaseStatusStr && <span className="status">{matchedCaseStatusStr}</span>}
                        </div>
                        {userId == matchedCaseUserId && <div className='middle'>{t('pages.MatchedCasePage.325802-21')}</div>}
                        <div className="right">
                            <div className="matched-case-status">
                                {matchedPercent && <span className="match-percent">{matchedLevel}</span>}
                            </div>
                        </div>
                    </div>
                    
                    <div className="content">{parse(matchedCaseText)}</div>
                    {
                    (isUnlocked || isPremiumUser || (userId == matchedCaseUserId)) ?  
                        <div className="toolbar">
                            {isMyTalent && this.renderButtons(matchedCaseStatus)}
                        </div> : 
                        <>
                            {balanceAmount >= 15 ? 
                            <div className='warning'>
                                <span>{t('pages.MatchedCasePage.325802-0', {amount:15})}<a onClick={this.onPurchaseClick}>{t('pages.MatchedCasePage.325802-1')}</a>{t('pages.MatchedCasePage.325802-2')}<a onClick={this.onPurchasePremiumUserClick}>{t('pages.MatchedCasePage.325802-3')}</a>{t('pages.MatchedCasePage.325802-4')}</span>
                            </div> :
                            <div className="warning">
                                <Flex vertical justify='center' align='center'>
                                    <span>{t('pages.MatchedCasePage.325802-0', {amount:15})}<a style={{pointerEvents:"none", cursor: "default", color:"gray"}}>{t('pages.MatchedCasePage.325802-1')}</a>{t('pages.MatchedCasePage.325802-2')}<a onClick={this.onPurchasePremiumUserClick}>{t('pages.MatchedCasePage.325802-3')}</a>{t('pages.MatchedCasePage.325802-4')}</span>
                                    <span style={{fontSize: "90%"}}>{t('pages.MatchedCasePage.325802-5')}{t('pages.MatchedCasePage.325802-6', {amount:balanceAmount})}<a href={`/balance?redirect=${encodeURIComponent(window.location.href)}`}>{t('pages.MatchedCasePage.325802-7')}</a>{t('pages.MatchedCasePage.325802-8')}</span>
                                </Flex>
                            </div>
                            }
                        </>
                    }
                </div>

                {/* 交流信息 */}
                {(isUnlocked || isPremiumUser) && (talentId && matchedCaseId && (isMyTalent && (talentUserId != matchedCaseUserId))) && 
                <Chat type={CaseOrTalent.TALENT} loginUserId={userId} caseId={matchedCaseId} talentId={talentId} placeholder={t('pages.MatchedCasePage.325802-9')} ref={this.chatRef} ></Chat>}

                {/* 该要员的营业状况（面试安排） */}
                <InterviewList title={t('pages.MatchedCasePage.325802-10')} list={matchedTalentInterviews} refCaseId={matchedCaseId} showCase={true} ref={this.talentInterviewRef} />
                
                <ConfirmDialogModal ref={this.confirmDialogRef} />
                <MessageModal message={this.state.message} />
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    renderButtons(matchedCaseStatus: number) {
        const { t } = this.props
        switch (matchedCaseStatus) {
            case MatchedCaseStatus.OPEN:{
                return (
                    <div className="right">
                        <button onClick={this.onDiscardClick}>{t('pages.MatchedCasePage.325802-11')}</button>
                        <button onClick={this.onAlternativeClick}>{t('pages.MatchedCasePage.325802-12')}</button>
                    </div>
                )
            }
            case MatchedCaseStatus.DISCARD:{
                return (
                    <div className="right">
                        <button onClick={this.onAlternativeClick}>{t('pages.MatchedCasePage.325802-12')}</button>
                    </div>
                )
            }
            case MatchedCaseStatus.ALTERNATIVE:{
                return (
                    <div className="right">
                        <button onClick={this.onDiscardClick}>{t('pages.MatchedCasePage.325802-11')}</button>
                    </div>
                )
            }
            case MatchedCaseStatus.EMPLOY_REQUEST: {
                return (
                    <div className="right">
                        <button onClick={this.onAceeptEmployRequestClick}>{t('pages.MatchedCasePage.325802-13')}</button>
                        <button onClick={this.onRefuseEmployRequestClick}>{t('pages.MatchedCasePage.325802-14')}</button>
                    </div>
                )
            }
            case MatchedCaseStatus.EMPLOY_DONE: {
                return(<></>)
            }
            default:
                return(<></>)
        }
    }

    // 放弃 
    onDiscardClick = () => {
        const { t } = this.props
        Server.match.matchedCaseDiscard(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
            this.setState({
                matchedCaseStatus: MatchedCaseStatus.DISCARD,
                alert: t('pages.MatchedCasePage.325802-15')
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedCasePage.325802-16')
            })
        })
    }

    // 备选
    onAlternativeClick = () => {
        const { t } = this.props
        Server.match.matchedCaseAlternative(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
            this.setState({
                matchedCaseStatus: MatchedCaseStatus.ALTERNATIVE,
                alert: t('pages.MatchedCasePage.325802-15')
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedCasePage.325802-16')
            })
        })
    }

    // 拒绝采用申请
    onRefuseEmployRequestClick = () => {
        const { t } = this.props
        this.confirmDialogRef.current.build({
            message: t('pages.MatchedCasePage.325802-17'),
            okText: t('pages.MatchedCasePage.325802-18'),
            okCallback: ()=> {
                this.onRequestRefusedCallback()
            },
            cancelText: t('pages.MatchedCasePage.325802-19'),
            cancelCallback: () => {
                // console.log(t('pages.MatchedCasePage.325802-19'))
            }
        })
    }
    onRequestRefusedCallback = () => {
        const { t } = this.props
        Server.match.matchedCaseEmployRequestRefused(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
            this.setState({
                matchedCaseStatus: MatchedCaseStatus.DISCARD,
                alert: t('pages.MatchedCasePage.325802-15')
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedCasePage.325802-16')
            })
        })
    }

    // 同意采用申请
    onAceeptEmployRequestClick = () => {
        const { t } = this.props
        this.confirmDialogRef.current.build({
            message: t('pages.MatchedCasePage.325802-20'),
            okText: t('pages.MatchedCasePage.325802-18'),
            okCallback: ()=> {
                this.onRequestAcceptCallback()
            },
            cancelText: t('pages.MatchedCasePage.325802-19'),
            cancelCallback: () => {
                // console.log(t('pages.MatchedCasePage.325802-19'))
            }
        })
    }
    onRequestAcceptCallback = () => {
        const { t } = this.props
        Server.match.matchedCaseEmployRequestAccept(this.id).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }
            this.setState({
                matchedCaseStatus: MatchedCaseStatus.EMPLOY_DONE,
                alert: t('pages.MatchedCasePage.325802-15')
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('pages.MatchedCasePage.325802-16')
            })
        })
    }

    // 激活
    onPurchaseClick = () => {
        const mid = this.id
        const caseId = this.state.matchedCaseId
        const talentId = this.state.talentId
        const url = window.location.pathname + '?id=' + this.id
        window.location.href='/purchase?gtype='+GoodsCategory.CASE + '&unlock=' + UnlockType.CASE + '&m='+ mid + '&c='+caseId + '&t='+talentId + '&url='+ encodeURIComponent(url)
    }

    // 购买会员
    onPurchasePremiumUserClick = () => {
        const url = window.location.pathname + '?id=' + this.id
        window.location.href='/purchase?gtype='+GoodsCategory.PREMIUM + '&url=' + encodeURIComponent(url)
    }

    getMatchedCaseInfo = () => {
        // 获取匹配信息
        this.setState({loading: true})
        Server.match.getMatchedCaseInfo(this.id).then(res => {
            if (res.success) {
                // console.log(JSON.stringify(res))
                const {
                    userId,
                    caseId,
                    caseUserId,
                    talentId,
                    talentBrief, 
                    talentUserId,
                    matchedType,
                    matchedPercent, 
                    matchedCaseText, 
                    matchedCaseStatus,
                    matchedTalentInterviews,
                    isUnlocked,
                    isPremiumUser
                } = res.data

                this.setState({
                    userId: userId,
                    talentId: talentId,
                    talentBrief: talentBrief,
                    talentUserId: talentUserId,
                    matchedType: matchedType,
                    matchedPercent: matchedPercent,
                    matchedCaseId: caseId,
                    matchedCaseUserId: caseUserId,
                    matchedCaseStatus: matchedCaseStatus,
                    matchedCaseText: matchedCaseText,
                    matchedTalentInterviews: matchedTalentInterviews,
                    isPremiumUser: isPremiumUser,
                    isUnlocked: isUnlocked,
                }, () => {
                    this.setState({loading: false})
                })

                if(!isUnlocked && !isPremiumUser) {
                    Server.account.balance().then(res => {
                        if (res.success && res.data) {
                            const {amount, version} = res.data
                            this.setState({
                                balanceAmount: Number(Number(amount).toFixed(0))
                            })
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                }
            }
        }).catch(err => {
            console.error(err)
            this.setState({loading: false})
        })
    }

}

export default withTranslation()(withNavigation(MatchedCasePage))

interface MatchedCasePageProps extends WithTranslation {
    // props: any;
}

interface MatchedCasePageState {
    // 当前登录用户
    userId: string,
    // 要员id
    talentId: string,
    // 要员概要
    talentBrief: string,
    // 要员所属用户id
    talentUserId: string,
    // 匹配类型（自动、人工）
    matchedType: number,
    // 匹配度（百分比）
    matchedPercent: string,
    // 匹配的案件id
    matchedCaseId: string,
    // 匹配的案件所属的用户id
    matchedCaseUserId: string,
    // 匹配的案件原文
    matchedCaseText: string,
    // 匹配的案件关系状态
    matchedCaseStatus: number,
    // 要员面试情况一览
    matchedTalentInterviews: any,
    // 当前用户是否付费会员用户
    isPremiumUser: boolean,
    // 匹配的案件是否已激活
    isUnlocked: boolean,
    // 用户余额（梧桐果个数）
    balanceAmount: number,
    // 提示消息
    message: string,
    // 错误消息
    alert: string,
    // 数据加载
    loading: boolean
}