import Api from "../app/api/Api";
import { Service } from "./service";

export class TalentService extends Service {

    constructor(){
        super()
    }

    public async searchTalents(params: any) {
        // 要员检索
        const {keyword, size, index} = params
        return await Api.post('/api/talent/search',{keyword, size, index})
    }

    public async getTopicTalents(topic: string) {
        // 获取主题要员
        return await Api.post('/api/talent/topic-talents',{topic: topic})
    }

    public async getManualMatchedCases(id: string) {
        // 获取某个要员手动匹配的案件列表
        return await Api.post('/api/talent/'+ id + '/manual-matched-cases')
    }

    public async manualMatch(talentId:string, caseId:string) {
        // 手动将要员和案件进行匹配
        return await Api.post('/api/talent/domatch', {talentId, caseId})
    }

    public async getMyTalentsNotMathed(caseId: string) {
        // 查询当前用户管理的要员且未与指定案件匹配的要员
        return await Api.post('/api/talent/my-talents-not-matched' , {caseId})
    }

    public async getTalentSampleInfo(id: string) {
        // 获取要员简要信息
        return await Api.get('/api/talent/'+id+'/sample')
    }

    public async getTalent(id: string) {
        // 获取要员详情（包含匹配的要员信息）
        return await Api.get('/api/talent/'+id)
    }

    public async getMyTalents() {
        // 获取我的案件（所有）
        return await Api.get('/api/talent/my-talents-all')
    }

    public async postTalent(talentHtml:string, talentText:string) {
        return await Api.post('/api/talent/create', {text: talentText, html: talentHtml})
    }

    public async openTalent(id:string) {
        return await Api.post('/api/talent/'+id+"/open")
    }

    public async closeTalent(id:string) {
        return await Api.post('/api/talent/'+id+"/close")
    }

    public async updateTalent(id:string, text:string, html:string) {
        return await Api.post('/api/talent/'+id+'/update', {text:text, html:html})
    }

    public async doMatch(id:string) {
        return await Api.post('/api/talent/'+id+'/domatch')
    }

    public async uploadResume(formData: FormData) {
        return await Api.upload('/api/file/resume/upload', formData)
    }

    public async downloadResume(filename: string) {
        return await Api.download('/api/file/resume/'+ filename, filename)
    }

    public async downloadResume2(filename: string) {
        return await Api.download('/api/file/resume2/'+ filename, filename)
    }
}