import { Card, Flex, Input, Select } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Server } from "../../server/server";
import { CaseOrTalent } from "../common/common";
import withNavigation from "../util/withNavigation";
import './HomePage.css';

class HomePage extends React.Component<HomePageProps, HomePageState> {
    private menuItems: any[]

    constructor(props: any) {
        super(props)
        this.state = {
            caseTopics: [],
            talentTopics: [],
            searchKey: -1,
            searchValue: '',
        }
    }
    
    componentDidMount(): void {
        this.init()
        Server.user.addEventListener('user-login', this.onUserLogin);
        Server.user.addEventListener('user-logout', this.onUserLogout);
    }

    componentWillUnmount(): void {
        Server.user.removeEventListener('user-login', this.onUserLogin);
        Server.user.removeEventListener('user-logout', this.onUserLogout);
    }

    render(): React.ReactNode {
        const {t} = this.props
        const caseBoxes = this.renderBoxes(CaseOrTalent.CASE, this.state.caseTopics)
        const talentBoxes = this.renderBoxes(CaseOrTalent.TALENT, this.state.talentTopics)
        // const {searchKey, searchValue} = this.state
        const isLogin = Server.user.isLogin()
        return (
            <div className="main">
                {/* <div className="">
                    <span>梧桐智配通过“智能算法”精准匹配案件与要员。<br/>
                    让每一名技术者都能第一时间找到最合适的案件，<br/>
                    让每一个案件都能精准找到合适的人，<br/>
                    让每一名营业员轻松营业，<br/>
                    让每一家会员企业高效、高产。
                    </span>
                </div> */}
                <Flex vertical={false} justify="flex-start" align="center" gap={15}>
                    <Select 
                        style={{width: '200px', height: '40px'}}
                        popupMatchSelectWidth={false} 
                        placeholder={t('pages.SearchPage.option.hold')}
                        options={this.menuItems} 
                        onChange={this.onSearchKeyChange} />
                    <div>
                        <Input
                            style={{width: 'auto', height: '40px'}}
                            name="searchValue"
                            // prefix={<SearchOutlined />}
                            count={{ show: true, max: 30, }}
                            allowClear
                            placeholder={t('pages.SearchPage.search.input.holder')}
                            onPressEnter={this.onSearchTap}
                            onChange={this.onSearchTextChange}
                        />
                    </div>
                    {
                        isLogin ? (
                            <button onClick={this.onSearchTap}>{t('pages.SearchPage.search.button')}</button>
                        ) : (
                            <button disabled>{t('pages.SearchPage.search.button')}</button>
                        )
                    }
                    
                </Flex>
                <div className="box-list">
                    {caseBoxes}
                </div>
                {(caseBoxes.length>0 && talentBoxes.length>0) && <hr className="line"></hr>}
                <div className="box-list">
                    {talentBoxes}
                </div>
            </div>
        )
    }

    renderBoxes(type:number, list: any[]) {
        const { t } = this.props
        let el: JSX.Element[] = []
        list.forEach((element, index) => {
            const id = element
            const title = t( (type == CaseOrTalent.CASE ? 'common.topic.case.': 'common.topic.talent.') + id)
            const link = `/topic?type=${type}&topic=${id}`
            const pic = (type == CaseOrTalent.CASE ? '/case-' : '/talent-') + id + '.png'
            el.push(
                <Card key={index} 
                    onClick={() => { window.location.href = link }}
                    hoverable
                    className="topic-box"
                    cover={<img alt="example" src={pic} />}>
                
                    <Meta title={title} description="" />
                </Card>
            )
        });

        return el
    }

    init = () =>  {
        Server.app.getTopics().then(res => {
            if (res.success) {
                let {caseTopics , talentTopics} = res.data
                caseTopics = caseTopics || []
                talentTopics = talentTopics || []
                this.setState({
                    caseTopics: caseTopics,
                    talentTopics: talentTopics
                })
            }
        })
 
        const { t } = this.props
        this.menuItems = [
            {
                value: CaseOrTalent.CASE,
                label: t('pages.SearchPage.option.case'),
            },
            {
                value: CaseOrTalent.TALENT,
                label: t('pages.SearchPage.option.talent'),
            }
        ]
    }

    // 用户登录回调函数
    onUserLogin = () => {
        console.log('用户登录事件')
        this.forceUpdate()
    }

    // 用户退出登录回调函数
    onUserLogout = () => {
        console.log('用户退出登录事件')
        this.forceUpdate()
    }

    onSearchKeyChange = (value: number) => {
        this.setState({
            searchKey: value
        })
    }

    onSearchTextChange = (e: any) => {
        const { value } = e.target
        this.setState({
            searchValue: value
        })
    }

    onSearchTap = async () => {
        const navigate = this.props.navigate
        navigate(`/search?type=${this.state.searchKey}&keyword=${this.state.searchValue}`)
    }
}

interface HomePageProps extends WithTranslation {
    navigate: any
}

interface HomePageState {
    caseTopics: any[]
    talentTopics: any[]
    searchKey: number
    searchValue: string
}

export default withTranslation()(withNavigation(HomePage))