import { Flex, Input, Pagination, Select } from "antd"
import qs from "qs"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { Server } from "../../server/server"
import { CaseOrTalent } from "../common/common"
import HtmlContainer from "../elements/HtmlContainer"
import ManualMatchedList from "../elements/ManualMatchedList"
import './TopicPage.css'
import AlertModal from "../modals/AlertModal"

class SearchPage extends React.Component<WithTranslation, SearchPageState> {

    private menuItems: any[]

    constructor(props: any) {
        super(props)
        const { search } = window.location
        const { type, keyword } = qs.parse(search.replace(/^\?/, ''))
        // console.log(type, keyword)
        const searchType = type != undefined ? Number(type): SearchKey.NONE
        const searchKeyword = keyword != undefined ? String(keyword): ''

        this.state = {
            search: {
                key: searchType,
                value: searchKeyword,
                pageIndex: 0,
                pageSize: 10,
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            alert: '',
            loading: false,
            searchDataList: [],
        }
    }

    componentDidMount(): void {
        // 创建下拉列表菜单
        const { t } = this.props
        this.menuItems = [
            {
                value: CaseOrTalent.CASE,
                label: t('pages.SearchPage.option.case'),
            },
            {
                value: CaseOrTalent.TALENT,
                label: t('pages.SearchPage.option.talent'),
            }
        ]

        if(this.state.search.key > -1 && this.state.search.value) {
            this.onSearchTap()
        } else {
            this.setState({
                search: {
                    ...this.state.search
                },
                pagination: {
                    ...this.state.pagination
                }
            })
        }
    }

    render(): React.ReactNode {
        const { t } = this.props
        const {key, value} = this.state.search
        const searchDataList = this.state.searchDataList || []
        let list: any = []
        searchDataList.forEach(item => {
            const id = item.id
            const text = item.text
            const card = (
                <div key={id} className="topic-card">
                    <HtmlContainer key={'h-' + id} id={id} html={text} />
                    <ManualMatchedList key={'m-' + id} type={key} id={id} />
                </div>
            )
            list.push(card)
        });

        const { pageSize, total, current} = this.state.pagination

        return (
            <div className="main">
                <Flex vertical={false} justify="flex-start" align="center" gap={15}>
                    {
                        key == -1 && 
                        <Select 
                            style={{width: '200px', height: '40px'}}
                            popupMatchSelectWidth={false} 
                            placeholder={t('pages.SearchPage.option.hold')}
                            options={this.menuItems} 
                            onChange={this.onSearchKeyChange} />
                    }
                    {
                        key > -1 && 
                        <Select 
                            style={{width: '200px', height: '40px'}}
                            popupMatchSelectWidth={false} 
                            placeholder={t('pages.SearchPage.option.hold')}
                            options={this.menuItems} 
                            defaultValue={key} 
                            onChange={this.onSearchKeyChange} />
                    }
                    <div>
                        <Input
                            style={{width: 'auto', height: '40px'}}
                            name="searchValue"
                            // prefix={<SearchOutlined />}
                            count={{ show: true, max: 30, }}
                            value={value}
                            allowClear
                            placeholder={t('pages.SearchPage.search.input.holder')}
                            onPressEnter={this.onSearchTap}
                            onChange={this.onSearchTextChange}
                        />
                    </div>
                    <button onClick={this.onSearchTap}>{t('pages.SearchPage.search.button')}</button>
                </Flex>
                {   
                    this.state.loading &&
                    <div>{t('pages.SearchPage.search.loading')}</div>
                }
                <div className='list'>
                    {!this.state.loading && searchDataList.length == 0 && <div>{t('pages.SearchPage.search.result.nodata')}</div>}
                    {!this.state.loading && searchDataList.length > 0 && <div>{list}</div>}
                </div>
                {
                    !this.state.loading && searchDataList.length > 0 && 
                    <Pagination onChange={this.onPageChange} responsive={true} pageSize={pageSize} total={total} current={current} showSizeChanger={false} />
                }
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    loadPageData = async () => {
        this.setState({ loading: true })
        const {key, value, pageIndex, pageSize} = this.state.search
        const params = { keyword: value.trim(), size: pageSize, index: pageIndex }
        // console.log(params)
        let res = null
        if (CaseOrTalent.CASE == key) {
            res = await Server.case.searchCases(params).catch((e) => { console.error(e); this.setState({ loading: false }) })
        } else if(CaseOrTalent.TALENT == key) {
            res = await Server.talent.searchTalents(params).catch((e) => { console.error(e); this.setState({ loading: false }) })
        } else {
            // 什么都不做
        }

        if (!res || !res.success) {
            if (res && res.message) {
                this.setState({ alert: res.message }) 
            }
            return this.setState({
                loading: false,
            })
        }

        const { list, total, size, index, /*pages*/ } = res.data
        return this.setState({
            searchDataList: list,
            pagination: {
                current: index + 1,
                pageSize: size,
                total: total,
            },
            loading: false
        })
    }

    onSearchKeyChange = (value: number) => {
        this.setState({
            search: {
                ...this.state.search,
                key: value,
            }
        })
    }

    onSearchTextChange = (e: any) => {
        const { value } = e.target
        this.setState({
            search: {
                ...this.state.search,
                value: value,
            }
        })
    }

    onSearchTap = async () => {
        const {t} = this.props
        const key = this.state.search.key
        if(!key || key === -1) {
            this.setState({
                alert: t('pages.SearchPage.search.required.type')
            })
            return
        }
        const value = this.state.search.value
        if(!value || value.trim().length === 0) {
            this.setState({
                alert: t('pages.SearchPage.search.required.keyword')
            })
            return
        }

        this.setState({
            search: {
                ...this.state.search,
                pageIndex: 0,
                pageSize: 10,
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            }
        }, () => {
            this.loadPageData()
        })
    }

    onPageChange = (page: number, pageSize: number) => {
        // console.log(page, pageSize)
        this.setState({
            search: {
                ...this.state.search,
                pageIndex: page - 1,
                pageSize: pageSize,
            },
            pagination: {
                ...this.state.pagination,
                current: page,
                pageSize: pageSize
            }
        }, () => {
            this.loadPageData()
        })
    }
}

export default withTranslation()(SearchPage)

interface SearchPageState {
    search: {
        key?: number
        value?: string
        pageSize?: number
        pageIndex?: number
    }
    pagination: {
        current: number
        pageSize: number
        total: number
    },
    alert: string,
    // 主题案件或要员列表
    searchDataList: SearchData[],
    // 数据加载标志位
    loading: boolean
}

interface SearchData {
    // 主题案件或要员的id
    id: string
    // 案件或要员的原文
    text: string
}

export enum SearchKey {
    NONE = -1,
    CASE = CaseOrTalent.CASE,
    TALENT = CaseOrTalent.TALENT
}